import { IProduct } from '../components/shop/shop.interface'
import React from 'react'
import Seo from '../components/Seo'
import ShopComponent from '../components/shop/shop-component'
import { graphql } from 'gatsby'

interface Props {
  data: {
    allShopifyProduct: {
      nodes: IProduct[]
    }
  }
}

const Shop: React.FC<Props> = ({ data }: Props) => {
  const products = data.allShopifyProduct.nodes

  return (
    <>
      <Seo
        title={'Vi tilbyder Danmarks mest eksklusive udvalg af vin af høj kvalitet til super skarpe priser.'}
        image={'/logo/logo.jpg'}
        description={`Mansted wine shop - Her finder du en række eksklusive vine, hvor fællesnævneren er frisk- og saftighed. Alle vine du finder her er håndplukket fra forskellige vingårde i europa!`}
      />
      <ShopComponent products={products} />
    </>
  )
}

export default Shop

export const ShopQuery = graphql`
  query {
    allShopifyProduct(filter: { collections: { elemMatch: { title: { ne: "Smagninger" } } } }) {
      nodes {
        descriptionHtml
        variants {
          storefrontId
          availableForSale
          compareAtPrice
          price
          inventoryQuantity
          selectedOptions {
            name
            value
          }
          image {
            gatsbyImageData
            originalSrc
          }
        }
        featuredImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 200)
          originalSrc
        }
        metafields {
          value
          key
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        title
        vendor
        tags
        collections {
          title
          metafields {
            value
          }
        }
      }
    }
  }
`
