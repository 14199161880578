import { Col, Row } from 'antd';
import {
  H1Bold,
  H2Bold,
  H3,
} from '../shared/typography';

import CollectionShop from '../collection.tsx/collection-shop';
import { IProduct } from './shop.interface';
import React from 'react';
import ShopCategories from './shop-categories';
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from 'gatsby-link';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

const CatBox = styled(Col)`
  height: 230px;
  width: 230px;
  background: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  .img {
    height: 190px;
    aspect-ratio: 1/1;
    object-fit: contain;
    transition: all 0.2s ease-in;
  }
  h3 {
    text-align: center;
  }
  &:hover {
    .img {
      transform: scale(1.05);
    }
  }
  @media(max-width: 766px){
    padding: 1rem;
    .img {
      height: calc(190px - 2rem);
    }
  }

`

type Props = {
  products: IProduct[];
};

const ShopComponent: React.FC<Props> = ({ products }: Props) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <ShopCategories />
      
      <div className="h-[100px] mt-8 py-8 bg-brandlight-darkred w-full">
        <H2Bold style={{textAlign: 'center', color: 'white'}}>Alle vine</H2Bold>
      </div>
      <CollectionShop products={products} title={'Alle vine'} />
    </div>
     
  );
};
export default ShopComponent;
